import { combineReducers } from "redux";

import shippings from "./shippings";
import slots from "./slots";
import returns from "./returns";
import account from "./account";
import bookings from "./bookings";
import messages from "./messages";
import language from "./language";
import pudos from "./pudos";

export default combineReducers({
  shippings,
  slots,
  returns,
  account,
  bookings,
  messages,
  language,
  pudos,
});
