import PinDefaultA2U from "../assets/a2u/pin-a2u-default.svg";
import PinDefaultTyp from "../assets/typ/pin-typ-default.svg";
import PinSelectedA2U from "../assets/a2u/pin-a2u-selected.svg";
import PinSelectedTyp from "../assets/typ/pin-typ-selected.svg";
import PinOriginTypDefault from "../assets/typ/punto-originario-default-typ.svg";
import PinOriginTypSelected from "../assets/typ/punto-originario-selected-typ.svg";
import PinOriginA2USelected from "../assets/a2u/punto-originario-a2u-selected.svg";
import PinOriginA2UDefault from "../assets/a2u/punto-originario-a2u-default.svg";

const pin_default = {
  a2u: PinSelectedA2U,
  typ: PinDefaultTyp,
};

export const PinDefault = pin_default[process.env.REACT_APP_TARGET];

const pin_selected = {
  a2u: PinDefaultA2U,
  typ: PinSelectedTyp,
};

export const PinSelected = pin_selected[process.env.REACT_APP_TARGET];

const pin_origin_default = {
  typ: PinOriginTypDefault,
  a2u: PinOriginA2UDefault,
};

export const PinOriginDefault =
  pin_origin_default[process.env.REACT_APP_TARGET];

const pin_origin_selected = {
  typ: PinOriginTypSelected,
  a2u: PinOriginA2USelected,
};

export const PinOriginSelected =
  pin_origin_selected[process.env.REACT_APP_TARGET];
