import React, { useEffect, useState } from "react";
import "./ShipmentsListPage.scss";
import { Text } from "../../assets/lemmata";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getAllShippings } from "../../reducers/shippings";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import { setReduxLanguage } from "../../reducers/language";

const viewportHeight = window.innerHeight;

const tableHeader = [
  "numeroTracking",
  "orderDate",
  "consignee",
  "lastStatusDate",
  "lastStatusCode",
  "reference",
  "mittente",
  "numeroOrdine",
  "",
];

const ShipmentsListPage = (props) => {
  const [completedDeliveries, setCompleteDeliveries] = useState([]);
  const [inProgressDeliveries, setInProgressDeliveries] = useState([]);
  const dispatch = useDispatch();
  const otpCode = localStorage.getItem("otpCode");
  const shippingList = useSelector((state) => state.shippings.shippingList);
  const errorList = useSelector((state) => state.shippings.errorList);
  console.log("shipmentList", shippingList);
  const [language, setLanguage] = useState(
    localStorage.getItem("lan") || navigator.language.toLowerCase()
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    if (shippingList?.length === 0) {
      dispatch(getAllShippings({ password: otpCode }));
    }
  }, []);

  useEffect(() => {
    if (shippingList) {
      setCompleteDeliveries(
        shippingList.filter((shipment) => shipment.lastEvent?.eventCode === 1)
      );
      setInProgressDeliveries(
        shippingList.filter((shipment) => shipment.lastEvent?.eventCode !== 1)
      );
    }
  }, [shippingList]);

  const isThereAnyDeliveries =
    !!completedDeliveries?.length || !!inProgressDeliveries?.length;

  const selectLanguage = (lan) => {
    setLanguage(lan);
    localStorage.setItem("lan", lan);
    setReduxLanguage(lan);
  };

  useEffect(() => {
    dispatch(getAllShippings({ password: otpCode }));
  }, [language]);

  return (
    <div className="main-container">
      <SideSection lan={language} />
      <div className="right-container" style={{ height: viewportHeight }}>
        <MobileHeader />
        <div style={{ flexGrow: 1 }}>
          <div
            className="flex-row title-container only-desktop"
            style={{ backgroundColor: "#fff", justifyContent: "space-between" }}
          >
            <div className="title-style-no-padding">
              <Text label={"elencoSpedizioni"} />
            </div>
            <LanguageSelection selectLanguage={selectLanguage} />
          </div>

          {!errorList && isThereAnyDeliveries && (
            <div className="list-container">
              {inProgressDeliveries?.length > 0 && (
                <div>
                  <div className="list-title">
                    <Text label={"spedizioniInCorso"} />
                  </div>
                  <ShipmentsList shippingsList={inProgressDeliveries} />
                </div>
              )}
              {completedDeliveries.length > 0 && (
                <div>
                  <div className="list-title">
                    <Text label={"spedizioniConcluse"} />
                  </div>
                  <ShipmentsList shippingsList={completedDeliveries} />
                </div>
              )}
            </div>
          )}
          {!errorList && !isThereAnyDeliveries && (
            <div className="error-container">
              <Text
                label="nessunaSpedizione"
                style={{ marginBottom: "1rem", color: "var(--primary)" }}
              />
              <a className="btn-mobile-style" href={`/find-shipments`}>
                <Text
                  style={{ fontWeight: 400, fontSize: "0.9rem" }}
                  label="tornaAllaRicerca"
                />
              </a>
            </div>
          )}
          {!!errorList && (
            <div className="error-container">
              <Text
                label="autorizzazioneScaduta"
                style={{ marginBottom: "1rem", color: "var(--primary)" }}
              />
              <a className="btn-mobile-style" href={`/find-shipments`}>
                <Text
                  style={{ fontWeight: 400, fontSize: "0.9rem" }}
                  label="richiediNuovoOtp"
                />
              </a>
            </div>
          )}
        </div>
        <MobileFooter />
      </div>
    </div>
  );
};

export default ShipmentsListPage;

const ShipmentsList = (props) => {
  return (
    <div className="shipments-list">
      <div className="table-header">
        {tableHeader.map((item, index) => {
          return (
            <div className="table-header-item" key={index}>
              {item && <Text label={item} />}
            </div>
          );
        })}
      </div>
      {props.shippingsList.map((shipment, i) => (
        <ShipmentItem
          key={`shipment_${shipment?.shipmentId}`}
          shipment={shipment}
        />
      ))}
    </div>
  );
};

const ShipmentItem = ({ shipment }) => {
  return (
    <>
      <div className="table-row">
        <div className="inbox-title-list">
          {tableHeader.map((item, index) => {
            return (
              <div className="table-header-item" key={index}>
                {item && <Text label={item} />}
              </div>
            );
          })}
        </div>
        <div className="data-list">
          <p className="data-cell">{shipment.shipmentId}</p>
          <p className="data-cell">
            {moment(shipment.createdAt).format("DD/MM/YYYY")}
          </p>
          <p className="data-cell">
            {shipment.deliveryAddress?.referencePerson ||
              shipment.deliveryAddress?.companyName}
          </p>
          <p className="data-cell">
            {moment(shipment.lastEvent?.dateTime).format("DD/MM/YYYY")}
          </p>
          <p className="data-cell">{shipment.lastEvent?.eventName}</p>
          <p className="data-cell">{shipment.reference}</p>
          <p className="data-cell">
            {shipment.senderAddress.companyName ||
              shipment.senderAddress.referencePerson}
          </p>
          <p className="data-cell">{shipment.orderNumber}</p>
          <p className="data-cell">
            <a
              className="btn-mobile-style"
              target="_blank"
              noopener="true"
              noreferrer="true"
              href={`/?id=${shipment?.shipmentId}&key=${shipment?.key}`}
            >
              <Text
                style={{ fontWeight: 400, fontSize: "0.7rem" }}
                label="vediSpedizione"
              />
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
