import React, { useState, useEffect } from "react";
import "./SideSection.scss";
import { connect } from "react-redux";
import { ReactComponent as Package } from "../../assets/icons/packageBlu.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { Text, translate } from "../../assets/lemmata";
import { ReactComponent as ArceseLogo } from "../../assets/icons/ArceseLogo.svg";
import { WhatsappBtn, ButtonLink } from "../../shared/Buttons";
import { Link, useLocation } from "react-router-dom";
import { Popup } from "../Popups/Popup";
import { Input, SingleDigitInput } from "../../shared/Inputs";
import { BtnSpinner } from "../../shared/Spinner";
import {
  activateNotifications,
  enableOtp,
  requestOtp,
  sendMessage,
} from "../../reducers/messages";
import { api } from "../../config/server";
import { ReactComponent as Phone } from "../../assets/icons/smartphone.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { BackBtn } from "../../shared/Buttons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
} from "../../reducers/shippings";
import { ReactComponent as WhatsappLogo } from "../../assets/icons/whatsapp-logo.svg";

// LOGO IN BASE A ENV
import Logo, { logoSize } from "../../assets/Logo";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const isDev = api.includes("dev") ? true : false;

const otpInputs = [
  { target: 1 },
  { target: 2 },
  { target: 3 },
  { target: 4 },
  { target: 5 },
  { target: 6 },
];

const SideSection = (props) => {
  const dispatch = useDispatch();
  const optInEnabled = useSelector(
    ({ shippings }) => shippings.data?.optInEnabled
  );
  const id = useQuery().get("id") || localStorage.getItem("id");
  const key = useQuery().get("key") || localStorage.getItem("key");
  const reference =
    useQuery().get("reference") || localStorage.getItem("reference");
  const customerCode =
    useQuery().get("customerCode") || localStorage.getItem("customerCode");
  const customerReference =
    useQuery().get("customerReference") ||
    localStorage.getItem("customerReference");
  const contractCode =
    useQuery().get("contractCode") || localStorage.getItem("contractCode");
  const lan = props.lan || localStorage.getItem("lan") || "en";
  const [warning, setWarning] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);
  const [whatsappInfo, setWhatsappInfo] = useState(null);
  const [verificationComplete, setVerificationComplete] =
    useState(optInEnabled);

  const isShippingDelivered = () => {
    if (props?.shipping?.events) {
      const isDelivered =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        1;
      return isDelivered;
    } else {
      return false;
    }
  };

  const isReturnAvailable = () => {
    return (
      props.shipping?.serviceCode?.toLowerCase() !== "s2c" &&
      props.shipping?.serviceCode?.toLowerCase() !== "r" &&
      isShippingDelivered() &&
      props.shipping.contractOptions?.endUserReturnAvailable &&
      props.shipping.provisionModeId !== 1 &&
      !props.shipping?.returnId &&
      window.location?.pathname !== "/find-shipments" &&
      window.location?.pathname !== "/shipments-list"
    );
  };

  const isAssistanceAvailable = () => {
    return (
      // (props.shipping?.serviceCode?.toLowerCase() === "sc" ||
      //   props.shipping?.serviceCode?.toLowerCase() === "r" ||
      //   props.shipping?.serviceCode?.toLowerCase() === "bc") &&
      !!props.shipping &&
      window.location?.pathname !== "/find-shipments" &&
      window.location?.pathname !== "/shipments-list"
    );
  };

  const isWhatsappAvailable =
    props.shipping?.contractOptions?.whatsappStream &&
    window.location?.pathname !== "/find-shipments" &&
    window.location?.pathname !== "/shipments-list";

  useEffect(() => {
    if (id) {
      localStorage.removeItem("id");
      localStorage.setItem("id", id);
    }
    if (key) {
      localStorage.removeItem("key");
      localStorage.setItem("key", key);
    }
    if (reference) {
      localStorage.removeItem("reference");
      localStorage.setItem("reference", reference);
    }
    if (customerCode) {
      localStorage.removeItem("customerCode");
      localStorage.setItem("customerCode", customerCode);
    }
    if (customerReference) {
      localStorage.removeItem("customerReference");
      localStorage.setItem("customerReference", customerReference);
    }
    if (contractCode) {
      localStorage.removeItem("contractCode");
      localStorage.setItem("contractCode", contractCode);
    }
  }, [id, key, reference, customerCode, customerReference, contractCode]);

  useEffect(() => {
    setVerificationComplete(optInEnabled);
  }, [optInEnabled]);

  useEffect(() => {
    if (
      !props.shipping &&
      window.location?.pathname !== "/" &&
      window.location?.pathname !== "/find-shipments" &&
      window.location?.pathname !== "/shipments-list"
    ) {
      if (reference) {
        dispatch(getTrackingDataWithCode(reference, contractCode, lan));
      }
      if (customerReference) {
        dispatch(
          getTrackingDataWithCustomerCode(customerReference, customerCode, lan)
        );
      }
      if (id || key) {
        console.log("start");
        dispatch(getTrackingData(id, key, lan));
      }
    }
  }, [props.shipping]);

  const inputList = [
    {
      placeholder: translate("oggettoRichiesta", lan),
      target: "subject",
    },
    {
      placeholder: translate("testoRichiesta", lan),

      target: "body",
      textarea: true,
    },
  ];

  const whatsappInputList = [
    {
      placeholder: translate("inserisciTuoNumero", lan),
      target: "to",
      icon: <Phone width={24} height={24} />,
      errorLabel: "numeroNonValido",
      prefixSelect: true,
    },
  ];

  const _handleClick = async () => {
    const response = await props.sendMessage(message, id);
    if (response) {
      setIsMessageSent(true);
      setTimeout(() => {
        setIsPopupOpen(false);
        setIsMessageSent(false);
      }, 5000);
    }
  };

  const _handleWhatsappClick = async () => {
    let number = whatsappInfo.to;
    if (whatsappInfo.to.startsWith("+")) {
      number = whatsappInfo.to.substring(1);
    }
    if (whatsappInfo.to.startsWith("00")) {
      number = whatsappInfo.to.substring(2);
    }

    const response = await activateNotifications(whatsappInfo.name, number);

    if (response) {
      setIsPopupOpen(false);
    }
  };

  const handleChange = (target, value) => {
    setMessage({
      ...message,
      [target]: value,
    });
  };

  const handleWhatsappChange = (target, value) => {
    setWhatsappInfo({
      ...whatsappInfo,
      [target]: value,
    });
  };

  return (
    <>
      {isPopupOpen === "contact" && (
        <PopUpContact
          title="scriviRichiesta"
          close={() => setIsPopupOpen(false)}
          inputs={inputList}
          disabled={!message.subject || !message.body}
          handleClick={_handleClick}
          handleChange={handleChange}
          buttonLabel="invia"
          isMessageSent={isMessageSent}
        />
      )}
      {isPopupOpen === "whatsapp" && (
        <PopUpWhatsapp
          title="whatsapp"
          subtitle="whatsappSub"
          secondPageTitle="verificaOtp"
          secondPageSubtitle="verificaOtpSub"
          close={() => setIsPopupOpen(false)}
          inputs={whatsappInputList}
          disabled={!whatsappInfo?.to}
          handleclick={_handleWhatsappClick}
          handleChange={handleWhatsappChange}
          buttonLabel="attivaServizio"
          secondPageButtonLabel="verifica"
          shipmentId={id}
          verificationComplete={verificationComplete}
          setVerificationComplete={setVerificationComplete}
        />
      )}
      <div className="side-section">
        <div
          style={{
            minHeight: 80,
            width: "100%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Logo
            style={{ width: logoSize, fill: "var(--toggleSectionIconColor)" }}
          />
        </div>
        <div style={{ width: "100%", overflowY: "auto" }}>
          {isAssistanceAvailable() && (
            <HelpSection
              textColor="var(--primary)"
              title="assistenzaClienti"
              body="assistenza"
              handleClick={() => setIsPopupOpen("contact")}
              buttonLabel="contattaci"
              backgroundColor="#fff"
            />
          )}
          {isWhatsappAvailable && (
            <HelpSection
              backgroundColorBtn="var(--bgSecondaryBtnColor)"
              textColorBtn="var(--textSecondaryBtnColor)"
              textColor="var(--textInSecondaryColor)"
              title="whatsapp"
              body={
                !verificationComplete
                  ? "inserisciNumero"
                  : "inserisciNumeroInterrompi"
              }
              handleClick={() => setIsPopupOpen("whatsapp")}
              buttonLabel={
                !verificationComplete
                  ? "attivaServizio"
                  : "interrompiAggiornamento"
              }
            />
          )}
          <div>
            {/*    <div className="buttons-container">
              {isReturnAvailable() && (
                <div className="return-container">
                  <div className="flex-row" style={{ paddingBottom: 8 }}>
                    <Package
                      width={32}
                      height={32}
                      style={{ marginRight: 8 }}
                    />
                    <div>
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: 600,
                          color: "var(--primary)",
                        }}
                        label="infoReso"
                      />
                    </div>
                  </div>
                  <Link
                    onClick={(event) => {
                      if (!id) {
                        event.preventDefault();
                        setWarning(true);
                      } else {
                        setWarning(false);
                      }
                    }}
                    className="link-style"
                    to={
                      key
                        ? {
                            pathname: "/reso",
                          }
                        : {
                            pathname: "/richiesta-link",
                            state: {
                              action: "RETURN",
                              title: "richiestaReso",
                            },
                          }
                    }
                  >
                    <ButtonLink
                      // disabled={!props.hasBackPage}
                      color="#fff"
                      backgroundColor="var(--primary)"
                      border="1px solid var(--primary)"
                      marginTop={"8px"}
                      style={{
                        textTransform: "uppercase",
                        fontWeight: 700,
                      }}
                    >
                      <Text style={{ fontSize: 10 }} label="gestisciReso" />
                    </ButtonLink>
                  </Link>
                  {warning && (
                    <Text
                      style={{
                        color: "var(--danger)",
                        fontSize: 12,
                        paddingTop: 8,
                        fontWeight: 500,
                      }}
                      label="inserisciTracking"
                    />
                  )}
                </div>
              )}
            </div> */}
            <div className="brand-container">
              <div className="flex-row-center">
                <span
                  style={{ fontSize: 10, paddingRight: 4, fontWeight: "light" }}
                >
                  Powered by
                </span>
                <ArceseLogo width={72} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.shippings.data,
  language: state.language.language,
  message: state.messages.data,
  loading: state.messages.loading,
  error: state.messages.error,
});

export default connect(mapStateToProps, {
  sendMessage,
  activateNotifications,
})(SideSection);

export const HelpSection = (props) => {
  return (
    <div
      className="whatsapp-container"
      style={{
        backgroundColor: props.backgroundColor,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.title === "whatsapp" && (
          <WhatsappLogo width={24} style={{ marginRight: 4 }} />
        )}
        <h4>
          <Text
            style={{
              textTransform: "uppercase",
              color: props.textColor,
            }}
            label={props.title}
          />
        </h4>
      </div>
      <div
        style={{
          padding: "16px 0px",
          fontSize: 12,
          fontWeight: 500,
        }}
      >
        <Text
          style={{
            color: props.textColor,
          }}
          label={props.body}
        />
      </div>
      <WhatsappBtn
        handleclick={props.handleClick}
        style={{
          backgroundColor: props.backgroundColorBtn,
          color: props.textColorBtn,
          border: "1px solid " + props.backgroundColorBtn,
        }}
      >
        <b>
          <Text style={{ fontSize: 10 }} label={props.buttonLabel} />
        </b>
      </WhatsappBtn>
    </div>
  );
};

////////////// POPUP COMPONENTS /////////////

export const PopUpWhatsapp = (props) => {
  const [requestSent, setRequestSent] = useState(false);
  const [otpCodeSent, setOtpCodeSent] = useState(false);

  const [prefix, setPrefix] = useState("+39");
  const [mobileNumber, setMobileNumber] = useState("");

  let defaultCode = otpInputs.reduce(
    (previousValue) => previousValue + "*",
    ""
  );
  const [otpCode, setOtpCode] = useState(defaultCode);

  const dispatch = useDispatch();

  const errorRequest = useSelector(({ messages }) => messages.errorRequest);
  const errorVerification = useSelector(
    ({ messages }) => messages.errorVerification
  );
  const optInEnabled = useSelector(
    ({ shippings }) => shippings.data?.optInEnabled || null
  );
  const loading = useSelector(({ messages }) => messages.loading);
  const requestId = useSelector(({ messages }) => messages.requestId);

  const handleChange = (t, value) => {
    setMobileNumber(value);
  };

  const handleCodeChange = (target, value, e) => {
    if (value.length === 1) {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + value + newCode.substring(target);
      setOtpCode(newCode);
    } else if (value.length > 1) {
      const numArray = value.split("");
      let newCode = otpCode;
      numArray.forEach((num, i) => {
        newCode = newCode.substring(0, i) + num + newCode.substring(i + 1);
      });
      setOtpCode(newCode);
    } else {
      let newCode = otpCode;
      newCode =
        newCode.substring(0, target - 1) + "*" + newCode.substring(target);
      setOtpCode(newCode);
    }
  };

  const handlePrefixSelection = (value) => {
    setPrefix(value);
  };

  const handleRequest = async () => {
    const request = await dispatch(
      requestOtp(
        prefix + mobileNumber,
        props.shipmentId,
        props.verificationComplete
      )
    );
    if (request) {
      setRequestSent(true);
    }
  };

  const handleEnableNumber = async () => {
    const request = await dispatch(
      enableOtp(otpCode, requestId, props.verificationComplete)
    );

    if (request) {
      props.setVerificationComplete(!props.verificationComplete);
      setOtpCodeSent(true);
    }
  };

  return (
    <Popup>
      {!requestSent ? (
        <>
          <h2>
            <Text label={props.title} />
          </h2>
          {props.subtitle && (
            <p>
              {props.verificationComplete ? (
                <Text label="cancelWhatsappSub" />
              ) : (
                <Text label={props.subtitle} />
              )}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          >
            <Close onClick={props.close} width={24} height={24} />
          </div>
          <div style={{ marginTop: 16 }}>
            {props.inputs.map((input) => (
              <Input
                key={`key_${input.target}`}
                target={input.target}
                placeholder={input.placeholder}
                handleChange={handleChange}
                textarea={input.textarea}
                icon={input.icon}
                error={errorRequest}
                errorLabel={errorRequest.status}
                prefixSelect={input.prefixSelect}
                prefixSelectHandleChange={handlePrefixSelection}
                value={mobileNumber}
                prefixSelectMarginTop={input.prefixSelectMarginTop}
              />
            ))}
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WhatsappBtn disabled={!mobileNumber} handleclick={handleRequest}>
              {!loading ? (
                <b>
                  {props.verificationComplete ? (
                    <Text
                      style={{ fontSize: 12 }}
                      label="interrompiAggiornamento"
                    />
                  ) : (
                    <Text style={{ fontSize: 12 }} label={props.buttonLabel} />
                  )}
                </b>
              ) : (
                <BtnSpinner color="#fff" />
              )}
            </WhatsappBtn>
          </div>
        </>
      ) : (
        <>
          {!otpCodeSent ? (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <BackBtn
                  height="24px"
                  width="30px"
                  onClick={() => setRequestSent(false)}
                >
                  <ArrowLeft width={16} height={16} />
                </BackBtn>
                <h2>
                  <Text label={props.secondPageTitle} />
                </h2>
              </div>
              {props.subtitle && (
                <div
                  style={{
                    display: "flex",
                    fontSize: "0.9rem",
                    flexWrap: "wrap",
                    marginTop: "0.5rem",
                  }}
                >
                  <Text label={props.secondPageSubtitle} />
                  <p style={{ fontWeight: 600, paddingLeft: 4 }}>
                    {prefix}
                    {mobileNumber}
                  </p>
                </div>
              )}
              <OtpInputBox
                otpInputs={otpInputs}
                handleCodeChange={handleCodeChange}
                otpCode={otpCode}
              />
              {errorVerification && (
                <Text
                  style={{
                    fontSize: "0.8rem",
                    fontWeight: "500",
                    color: "red",
                    // paddingLeft: "56px",
                  }}
                  label={errorVerification?.status}
                />
              )}
            </>
          ) : (
            <>
              <h2>
                <Text label="numeroVerificato" />
              </h2>
              <div
                style={{
                  fontSize: "0.9rem",
                  marginTop: "0.5rem",
                }}
              >
                {props.verificationComplete ? (
                  <Text label="ricezioneAggiornamenti" />
                ) : (
                  <Text label="aggiornamentiInterrotti" />
                )}
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              position: "absolute",
              top: "16px",
              right: "16px",
            }}
          >
            <Close onClick={props.close} width={24} height={24} />
          </div>
          {!otpCodeSent && (
            <>
              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WhatsappBtn
                  disabled={!!otpCode && otpCode.includes("*")}
                  handleclick={handleEnableNumber}
                >
                  {!loading ? (
                    <b>
                      <Text
                        style={{ fontSize: 12 }}
                        label={props.secondPageButtonLabel}
                      />
                    </b>
                  ) : (
                    <BtnSpinner color="#fff" />
                  )}
                </WhatsappBtn>
              </div>
            </>
          )}
        </>
      )}
    </Popup>
  );
};

export const PopUpContact = (props) => {
  return (
    <Popup>
      {props.isMessageSent ? (
        <div style={{ textAlign: "center", padding: "1rem 0" }}>
          <Text label="richiestaInviata" />
          <Text label="verificheremoRichiesta" />
          <Text label="grazie" />
        </div>
      ) : (
        <>
          <h2>
            <Text label={props.title} />
          </h2>
          <div style={{ marginTop: 16 }}>
            {props.inputs.map((input) => (
              <Input
                key={`key_${input.target}`}
                target={input.target}
                placeholder={input.placeholder}
                handleChange={props.handleChange}
                textarea={input.textarea}
              />
            ))}
          </div>
          <div
            style={{
              marginTop: 16,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <WhatsappBtn
              disabled={props?.disabled || false}
              handleclick={props.handleClick}
            >
              <b>
                <Text style={{ fontSize: 12 }} label={props.buttonLabel} />
              </b>
            </WhatsappBtn>
          </div>
        </>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          cursor: "pointer",
          position: "absolute",
          top: "16px",
          right: "16px",
        }}
      >
        <Close onClick={props.close} width={24} height={24} />
      </div>
    </Popup>
  );
};

export const OtpInputBox = (props) => {
  // const setFocus = (i) => {
  //   i.current && i.current.focus();
  // };

  const handleKeyPress = (e) => {
    if (e.which === 8 && e.target.value === "") {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else {
      if (e.target.value.length === 1 && e.which !== 8 && e.target.nextSibling)
        e.target.nextSibling.focus();
    }
  };

  const handleFocus = (e) => {
    // console.log("e ", e.target.value);
    // console.log("e ", e.target.previousSibling);
    if (e.target.value === "") {
      if (e.target.previousSibling) e.target.previousSibling.focus();
    } else {
      if (e.target.nextSibling) e.target.nextSibling.focus();
    }
  };
  return (
    <form
      style={{
        marginTop: 16,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {props.otpInputs.map((input) => {
        // const htmlElRef = useRef(null);
        return (
          <SingleDigitInput
            style={{ textAlign: "center" }}
            key={`key_${input.target}`}
            target={input.target}
            placeholder={input.placeholder}
            onKeyPress={handleKeyPress}
            handleChange={props.handleCodeChange}
            handleFocus={handleFocus}
            errorLabel={input.errorLabel}
            maxLength={"1"}
            value={
              props.otpCode[input.target - 1] !== "*" &&
              props.otpCode[input.target - 1]
            }
            disabled={props.disabled}
            // ref={}
          />
        );
      })}
    </form>
  );
};
