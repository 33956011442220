import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { translate } from "../assets/lemmata";
import Favicon from "../assets/Favicon";

function format() {
  const lan = localStorage.getItem("lan") || "it-it";
  let address = window.location.pathname.split("/");
  const translatedAddress = translate(address[1], lan);
  if (address[1] !== "") {
    return translatedAddress;
  } else {
    return "";
  }
}

const helmets = {
  typ: "Typ",
  a2u: "A2U",
};

function MyHelmet() {
  const [path, setPath] = useState(format());
  const { pathname } = useLocation();

  useEffect(() => {
    setPath(format());
  }, [pathname]);

  const helmet = helmets[process.env.REACT_APP_TARGET];
  return (
    <Helmet>
      <title>
        {helmet} {path && "-"} {path}
      </title>
      <link rel="icon" type="image/png" href={Favicon} sizes="32x32" />
    </Helmet>
  );
}

export default MyHelmet;
