export const groupEventsByState = (events, provision) => {
  const storageTime = events.find((event) => event.eventCode === 35)?.dateTime;

  /////////////////////// EVENT CODE //////////////

  // 0: Spedizione registrata
  // 1: Ritirata
  // 2: In transito
  // 3: In consegna
  // 4: Consegnata

  /////////////////////// DISPOSIZIONI //////////////

  // 1: Rientro al mittente
  // 2: Nuovo tentativo di consegna
  // 3: Cambio indirizzo
  // 4: Cliente ritira in magazzino
  // 5: Al macero
  // 7: Mittente ritira in magazzino

  const getStepByCode = (eventCode, provision, isEventAfterStorage) => {
    switch (true) {
      case isEventAfterStorage && provision === 1 && eventCode === 1:
        return { stepCode: 4, status: "warning", note: "" };
      case isEventAfterStorage && provision === 2 && eventCode === 1:
        // case isEventAfterStorage && provision === 3 && eventCode === 1:
        return { stepCode: 4, status: "ok", note: "" };
      case isEventAfterStorage && provision === 1:
      case isEventAfterStorage && provision === 3:
      case isEventAfterStorage && provision === 2 && eventCode === 11:
      case isEventAfterStorage && provision === 3 && eventCode === 11:
      case isEventAfterStorage && !provision && eventCode === 11:
        return { stepCode: 3, status: "warning", note: "" };
      case isEventAfterStorage && provision === 2:
      case isEventAfterStorage && !provision:
        return { stepCode: 3, status: "ok", note: "" };
      case eventCode === 90 && !provision:
        return { stepCode: 0, status: "error", note: "" };

      case eventCode === 2 && !provision:
      case eventCode === 50 && !provision:
      case eventCode === 50 && provision === 5:
      case eventCode === 50 && provision === 2:
      case eventCode === 50 && provision === 1:
      case eventCode === 50 && provision === 3:
      case eventCode === 58 && !provision:
        return { stepCode: 0, status: "ok", note: "" };
      //
      case eventCode === 47:
      case eventCode === 48:
      case eventCode === 49 && !provision:
      case eventCode === 65 && !provision:
      case eventCode === 72:
      case eventCode === 77 && !provision:
      case eventCode === 91:
      case eventCode === 4 && !provision:
        return { stepCode: 2, status: "ok", note: "" };
      // return { stepCode: 2, status: "error", note: "" };
      case eventCode === 93 && !provision:
        return { stepCode: 2, status: "warning", note: "" };
      //
      case eventCode === 51 && !provision:
      case eventCode === 52 && !provision:
      case eventCode === 82:
      case eventCode === 52 && provision === 1:
        return { stepCode: 3, status: "ok", note: "" };
      case eventCode === 31 && !provision:
      case eventCode === 35 && provision === 5:
        return { stepCode: 3, status: "error", note: "" };
      case eventCode === 35 && !provision:
      case eventCode === 62 && !provision:
      case eventCode === 76:
      case eventCode === 35 && provision === 4:
      case eventCode === 35 && provision === 7:
      case eventCode === 35 && provision === 2:
      case eventCode === 35 && provision === 1:
      case eventCode === 35 && provision === 3:
      case eventCode === 11 && provision === 4:
      case eventCode === 11 && provision === 7:
      case eventCode === 11 && provision === 2:
      case eventCode === 11 && provision === 1:
      case eventCode === 11 && provision === 3:
      case eventCode === 11 && !provision:
      case eventCode === 11 && provision === 5:
      case eventCode === 11:
        return { stepCode: 3, status: "warning", note: "" };
      //
      case eventCode === 36 && !provision:
      case eventCode === 37 && !provision:
      case eventCode === 70 && !provision:
        return { stepCode: 1, status: "ok", note: "" };
        // case eventCode === 62 && !provision:
        // case eventCode === 35 && provision === 5:
        return { stepCode: 1, status: "error", note: "" };
      case eventCode === 77 && !provision:
        // case eventCode === 76 && !provision:

        // case eventCode === 35 && provision === 4:
        // case eventCode === 35 && provision === 7:
        // case eventCode === 35 && provision === 2:
        return { stepCode: 1, status: "warning", note: "" };
      case eventCode === 1 && !provision:
      case eventCode === 83 && !provision:
        return { stepCode: 4, status: "ok", note: "" };

      case eventCode === 1 && provision === 1:
      case eventCode === 83 && provision === 1:
        return { stepCode: 4, status: "warning", note: "" };

      default:
    }
  };

  return events.map((event) => {
    const isEventAfterStorage = event.dateTime > storageTime;
    return {
      ...event,
      ...getStepByCode(event.eventCode, provision, isEventAfterStorage),
    };
  });
};

export const getReachedStep = (events) => {
  const eventsWithOk = events.filter((event) => {
    return (
      event.status === "ok" ||
      event.status === "warning" ||
      event.status === "error"
    );
  });
  // const stepCodeArray = eventsWithOk.reduce((acc, curr) => {
  //   return [...acc, curr.stepCode];
  // }, []);
  const stepCodeArray = eventsWithOk.map((ev) => ev.stepCode);

  return Math.max(...stepCodeArray);
};

export const isLastEventWarning = (events) => {
  const lastEvent = events[events.length - 1];
  return lastEvent.status === "warning";
};

export const isSectionWarning = (events, sectionId) => {
  const sectionEvent = events.filter((ev) => ev.stepCode === sectionId);
  const lastEvent = sectionEvent[sectionEvent.length - 1];
  return lastEvent?.status === "warning";
};

export const isSectionError = (events, sectionId) => {
  const sectionEvent = events.filter((ev) => ev.stepCode === sectionId);
  const lastEvent = sectionEvent[sectionEvent.length - 1];
  return lastEvent?.status === "error";
};

export const isLastEventError = (events) => {
  const lastEvent = events[events.length - 1];
  return lastEvent.status === "error" || lastEvent.status === "warning";
};
