// import FaviconA2U from "../assets/a2u/favicon.ico";
import FaviconA2U from "../assets/a2u/favicon.png";
import FaviconTyp from "../assets/typ/favicon.ico";

const favicons = {
  a2u: FaviconA2U,
  typ: FaviconTyp,
};

export default favicons[process.env.REACT_APP_TARGET];
