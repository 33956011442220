import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection from "../../components/SideSection/SideSection";
import moment from "moment";
import { setReduxLanguage } from "../../reducers/language";
import "../TrackingPage/TrackingPage.scss";
import { sendMessage } from "../../reducers/messages";
// import { useSelector } from "react-redux";
import FindShipmentsSection from "../../components/FindShipmentsSection/FindShipmentsSection";

// const isWhatsappAvailable = api.includes("dev") ? true : false;

const TrackingPage = (props) => {
  // const shippingLogo = useSelector(
  //   ({ shippings }) => shippings.shippingLogo || null
  // );
  // const lan = localStorage.getItem("lan") || navigator.language.toLowerCase();
  const [language, setLanguage] = useState(
    localStorage.getItem("lan") || navigator.language.toLowerCase()
  );

  const isWhatsappAvailable = props.shipping?.contractOptions?.whatsappStream;

  const selectLanguage = (lan) => {
    setLanguage(lan);
    setReduxLanguage(lan);
  };

  useEffect(() => {
    if (
      language.toLowerCase().includes("it") ||
      language.includes("en") ||
      language.includes("es")
    ) {
      localStorage.setItem("lan", language);
      moment.locale(language);
      props.setReduxLanguage(language);
      setLanguage(language);
    } else {
      localStorage.setItem("lan", "en");
      moment.locale("en");
      props.setReduxLanguage("en");
    }
  }, [language]);

  return (
    <>
      <div className="main-container">
        <SideSection isWhatsappAvailable={isWhatsappAvailable} />
        <div className="right-container">
          <MobileHeader />
          <div className="main-header" style={{ justifyContent: "flex-end" }}>
            {/* <div className="container-logo">
              {shippingLogo && (
                <img
                  className="logo"
                  src={`data:${shippingLogo.contentType};base64, ${shippingLogo.content}`}
                  alt={shippingLogo.fileName}
                />
              )}
            </div> */}

            <LanguageSelection selectLanguage={selectLanguage} />
          </div>

          <FindShipmentsSection language={language} />

          <MobileFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  shipping: state.shippings.data,
  loading: state.shippings.loading,
  error: state.shippings.error,
});

export default connect(mapStateToProps, {
  setReduxLanguage,
  sendMessage,
})(TrackingPage);
