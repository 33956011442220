import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Package } from "../../assets/icons/packageBlu.svg";
import { ReactComponent as Timer } from "../../assets/icons/timer-white.svg";
// import { ReactComponent as TimerBlack } from "../../assets/icons/timer.svg";
// import { ReactComponent as WhatsappLogo } from "../../assets/icons/whatsapp.svg";6
import { Text, translate } from "../../assets/lemmata";
import { Card } from "../../components/Cards/Cards";
import LanguageSelection from "../../components/LanguageSelection/LanguageSelection";
import MobileFooter from "../../components/MobileFooter/MobileFooter";
import MobileHeader from "../../components/MobileHeader/MobileHeader";
import SideSection, {
  PopUpWhatsapp,
  PopUpContact,
  HelpSection,
} from "../../components/SideSection/SideSection";
import {
  getShippingLogo,
  getTrackingData,
  getTrackingDataWithCode,
  getTrackingDataWithCustomerCode,
} from "../../reducers/shippings";
import { setReduxLanguage } from "../../reducers/language";
import { ButtonLink } from "../../shared/Buttons";
// import { CheckCircle, LightCheckCircle } from "../../shared/CheckCircle";
import { Spinner } from "../../shared/Spinner";
import "./TrackingPage.scss";
// import { InputWithButton } from "../../shared/Inputs";
import PodMap from "../../components/Map/Map";
import { activateNotifications, sendMessage } from "../../reducers/messages";
// import { api } from "../../config/server";
import { ReactComponent as Phone } from "../../assets/icons/smartphone.svg";
import { ReactComponent as Envelope } from "../../assets/icons/mail.svg";
import { useSelector } from "react-redux";
import TrackingSection from "../../components/TrackingSection/TrackinSection";
import { groupEventsByState } from "../../components/TrackingSection/FormatEventsFunc";
import { useDispatch } from "react-redux";
// import FindShipmentsSection from "../../components/FindShipmentsSection/FindShipmentsSection";
import { Redirect } from "react-router-dom";
import { getAllPudos } from "../../reducers/pudos";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
// const isWhatsappAvailable = api.includes("dev") ? true : false;

// const lan = localStorage.getItem("lan") || "it";

const TrackingPage = (props) => {
  const dispatch = useDispatch();

  const optInEnabled = useSelector(
    ({ shippings }) => shippings.data?.optInEnabled || null
  );
  const shippingLogo = useSelector(
    ({ shippings }) => shippings.shippingLogo || null
  );

  const pudos = useSelector(({ pudos }) => pudos.data?.pudos || null);
  // const lan = localStorage.getItem("lan") || navigator.language.toLowerCase();

  const [language, setLanguage] = useState(
    localStorage.getItem("lan") ||
      props.language ||
      navigator.language.toLowerCase()
  );
  const localLanguage = localStorage.getItem("lan") || null;
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [whatsappInfo, setWhatsappInfo] = useState(null);
  // const [trackingCode, setTrackingCode] = useState("");
  const [shipping, setShipping] = useState(null);

  const id = useQuery().get("id") || localStorage.getItem("id");
  const key = useQuery().get("key") || localStorage.getItem("key");
  const urlId = useQuery().get("id");
  const urlKey = useQuery().get("key");
  const reference = useQuery().get("reference") || null;
  const customerCode = useQuery().get("customerCode");
  const customerReference = useQuery().get("customerReference");
  const contractCode = useQuery().get("contractCode");
  const [verificationComplete, setVerificationComplete] =
    useState(optInEnabled);

  const isWhatsappAvailable = props.shipping?.contractOptions?.whatsappStream;

  const isShippingDelivered = () => {
    if (props?.shipping?.events) {
      const isDelivered =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        1;
      return isDelivered;
    } else {
      return false;
    }
  };

  const isShippingDelivering = () => {
    if (props?.shipping?.events) {
      const isDelivering =
        props.shipping.events[props.shipping.events.length - 1]?.eventCode ===
        52;
      return isDelivering;
    } else {
      return false;
    }
  };

  const isReturnPickedUp = () => {
    if (props?.shipping?.events) {
      const isPickedUp = props.shipping?.events.some(
        (event) => event.eventCode === 37
      );
      const isReturn = props.shipping.serviceCode === "R";
      return isPickedUp && isReturn;
    } else {
      return false;
    }
  };

  const isReturnAvailable = () => {
    return (
      props.shipping?.serviceCode?.toLowerCase() !== "s2c" &&
      props.shipping?.serviceCode?.toLowerCase() !== "r" &&
      isShippingDelivered() &&
      props.shipping.contractOptions?.endUserReturnAvailable &&
      props.shipping.provisionModeId !== 1 &&
      !props.shipping?.returnId
    );
  };

  const isAssistanceAvailable = () => {
    return !!props.shipping;
    // &&
    // (props.shipping?.serviceCode?.toLowerCase() === "sc" ||
    //   props.shipping?.serviceCode?.toLowerCase() === "r" ||
    //   props.shipping?.serviceCode?.toLowerCase() === "bc")
  };

  const isSchedulingAvailable = () => {
    return (
      !isShippingDelivered() &&
      // !isShippingDelivering() &&
      !isReturnPickedUp() &&
      !props.shipping?.warehouseStorageId &&
      // props.shipping.contractOptions.endUserReturnAvailable &&
      (props.shipping?.serviceCode?.toLowerCase() === "sc" ||
        props.shipping?.serviceCode?.toLowerCase() === "r" ||
        props.shipping?.serviceCode?.toLowerCase() === "bc")
      // ||
      // props.shipping?.serviceCode?.toLowerCase() === "r"
    );
  };

  const inputList = [
    {
      placeholder: translate("oggettoRichiesta", language),
      target: "subject",
    },
    {
      placeholder: translate("testoRichiesta", language),

      target: "body",
      textarea: true,
    },
  ];

  const whatsappInputList = [
    // {
    //   placeholder: lan.includes("it")
    //     ? "Inserisci il tuo nome"
    //     : "Insert your name",
    //   target: "name",
    // },
    {
      placeholder: translate("inserisciTuoNumero", language),
      target: "to",
      icon: <Phone width={24} height={24} />,
      errorLabel: "numeroNonValido",
      prefixSelect: true,
    },
  ];

  const _handleClick = async () => {
    const response = await props.sendMessage(message, id);
    if (response) {
      setIsPopupOpen(false);
    }
  };

  const _handleWhatsappClick = async () => {
    let number = whatsappInfo.to;
    if (whatsappInfo.to.startsWith("+")) {
      number = whatsappInfo.to.substring(1);
    }
    if (whatsappInfo.to.startsWith("00")) {
      number = whatsappInfo.to.substring(2);
    }

    const response = await activateNotifications(whatsappInfo.name, number);

    if (response) {
      setIsPopupOpen(false);
    }
  };

  const handleChange = (target, value) => {
    setMessage({
      ...message,
      [target]: value,
    });
  };

  const handleWhatsappChange = (target, value) => {
    setWhatsappInfo({
      ...whatsappInfo,
      [target]: value,
    });
  };

  const formatTimeData = (timeData) => {
    const timeDataSplitted = timeData.split("-");
    let dataStart = timeDataSplitted[0].trim();
    let dataEnd = timeDataSplitted[1].trim();
    if (dataStart.length < 3) {
      dataStart = dataStart + ":00";
    }
    if (dataEnd.length < 3) {
      dataEnd = dataEnd + ":00";
    }
    return isShippingDelivered()
      ? `${translate("betweenHours", language)} ${dataStart} ${translate(
          "andHours",
          language
        )} ${dataEnd}`
      : `${translate("fromHours", language)} ${dataStart} ${translate(
          "toHours",
          language
        )} ${dataEnd}`;
  };
  useEffect(() => {
    if (
      urlId ||
      urlKey ||
      reference ||
      customerCode ||
      customerReference ||
      contractCode
    ) {
      localStorage.removeItem("customerCode");
      localStorage.removeItem("contractCode");
      localStorage.removeItem("customerReference");
      localStorage.removeItem("reference");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
    }
    if (!urlId && !id && !customerCode && !customerReference && !contractCode) {
      setShipping("not_find");
    }
  }, []);

  useEffect(() => {
    if (reference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      dispatch(getTrackingDataWithCode(reference, contractCode, language));
    }
  }, [reference, language]);

  useEffect(() => {
    if (customerReference) {
      localStorage.removeItem("id");
      localStorage.removeItem("key");
      dispatch(
        getTrackingDataWithCustomerCode(
          customerReference,
          customerCode,
          language
        )
      );
    }
  }, [customerReference, language]);

  useEffect(() => {
    if (urlId || urlKey) {
      window.scrollTo(0, 0);
      localStorage.setItem("id", id);

      if (urlKey) {
        localStorage.setItem("key", urlKey);
      }
      dispatch(getTrackingData(id, key, language));
      if (key) {
        dispatch(getAllPudos(id, key));
      }
    } else if (id || key) {
      dispatch(getTrackingData(id, key, language));
      if (key) {
        dispatch(getAllPudos(id, key));
      }
    }
  }, [id, key, language]);

  useEffect(() => {
    if (
      language.includes("it") ||
      language.includes("en") ||
      language.includes("es")
    ) {
      localStorage.setItem("lan", language);
      moment.locale(language);
      props.setReduxLanguage(language);
    } else {
      localStorage.setItem("lan", "en");
      moment.locale("en");
      props.setReduxLanguage("en");
      setLanguage("en");
    }
  }, [language]);

  useEffect(() => {
    if (props.shipping) {
      if (props.shipping?.contractId) {
        dispatch(getShippingLogo(props.shipping.contractId));
      }
      if (props.shipping?.shipmentId) {
        setShipping(true);
      } else {
        setShipping("not_find");
      }
    }
    if (props.error) {
      // setShipping("not_find");
      localStorage.removeItem("customerCode");
      localStorage.removeItem("customerReference");
      localStorage.removeItem("reference");
      localStorage.removeItem("id");
      localStorage.removeItem("key");
    }
  }, [props.shipping, props.error]);

  useEffect(() => {
    setVerificationComplete(optInEnabled);
  }, [optInEnabled]);

  const selectLanguage = (lan) => {
    setLanguage(lan);
    setReduxLanguage(lan);
  };

  const getKoReason = () => {
    const koEventId = props.shipping?.events?.find(
      (event) => event.eventCode === 11
    )?.eventId;
    if (props.shipping?.parcels?.length > 0) {
      const koParcel = props.shipping.parcels?.map((parcel) =>
        parcel?.events?.find((event) => event.shipmentEventId === koEventId)
      );

      if (!!koParcel[0]) {
        return koParcel[0]?.eventName;
      }
    }
    return null;
  };

  const customMessage = props.shipping?.notes?.find(
    (note) => note.noteType === "MERCHANT_TO_ENDUSER"
  );

  // console.log("SHIPPING", props.shipping);
  // console.log("pudoSetted", props.pudoSetted);

  return (
    <>
      {isPopupOpen === "contact" && (
        <PopUpContact
          title="scriviRichiesta"
          close={() => setIsPopupOpen(false)}
          inputs={inputList}
          disabled={!message.subject || !message.body}
          handleClick={_handleClick}
          handleChange={handleChange}
          buttonLabel="invia"
        />
      )}
      {isPopupOpen === "whatsapp" && (
        <PopUpWhatsapp
          title="whatsapp"
          subtitle="whatsappSub"
          secondPageTitle="verificaOtp"
          secondPageSubtitle="verificaOtpSub"
          close={() => setIsPopupOpen(false)}
          inputs={whatsappInputList}
          disabled={!whatsappInfo?.to}
          handleclick={_handleWhatsappClick}
          handleChange={handleWhatsappChange}
          buttonLabel="attivaServizio"
          secondPageButtonLabel="verifica"
          shipmentId={id}
          verificationComplete={verificationComplete}
          setVerificationComplete={setVerificationComplete}
        />
      )}
      <div className="main-container">
        <SideSection
          id={props.match.params.id}
          key={props.match.params.key}
          isReturnAvailable={isReturnAvailable}
          isAssistanceAvailable={isAssistanceAvailable}
          isWhatsappAvailable={isWhatsappAvailable}
          serviceCode={props.shipping?.serviceCode}
        />
        <div className="right-container">
          <MobileHeader />
          <div className="main-header">
            <div className="container-logo">
              {shippingLogo && (
                <img
                  className="logo"
                  src={`data:${shippingLogo.contentType};base64, ${shippingLogo.content}`}
                  alt={shippingLogo.fileName}
                />
              )}
            </div>

            <LanguageSelection selectLanguage={selectLanguage} />
          </div>
          {!props.loading && props.error && (
            <div className="error-container">
              <Text
                label="idNonTrovato"
                style={{ marginBottom: "1rem", color: "var(--primary)" }}
              />
              <a className="btn-mobile-style" href={`/find-shipments`}>
                <Text
                  style={{ fontWeight: 400, fontSize: "0.9rem" }}
                  label="tornaAllaRicerca"
                />
              </a>
            </div>
          )}

          {!props.loading && shipping && (
            <>
              {shipping === "not_find" ? (
                <>
                  <Redirect to="find-shipments" />
                </>
              ) : (
                <div style={{ padding: "32px 5%" }}>
                  <h2
                    style={{
                      fontWeight: 600,
                      color: "var(--primary)",
                    }}
                  >
                    {`${translate("trackingOrdine", language)} ${
                      props.shipping.shipmentId
                    }`}
                  </h2>
                  <p
                    style={{
                      color: "var(--primary)",
                    }}
                  >
                    {(props.shipping.orderNumber || props.shipping.reference) &&
                      `${translate("ordine", language)} ${
                        props.shipping.orderNumber || props.shipping.reference
                      }`}
                    {props.shipping?.serviceCode !== "R" &&
                      props.shipping.senderAddress?.companyName &&
                      ` ${translate("da", language)} ${
                        props.shipping.senderAddress.companyName
                      }`}
                  </p>
                  {!!customMessage && (
                    <div>
                      <Card
                        className="card custom-message-card"
                        // cardDirection="row"
                        // cardTitle={<Text label="messaggioCustom" />}
                        cardJustifyContent="space-between"
                        backgroundColor="var(--toggleSectionIconColor)"
                        cardAlignItems="center"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // justifyContent: "space-between",
                            marginBottom: "1rem",
                          }}
                          className="custom-message-title"
                        >
                          <Envelope
                            style={{
                              width: 36,
                              height: 36,
                              fill: "var(--envelopeColor)",
                              // fill: "var(--primary)",
                              marginRight: "0.5rem",
                            }}
                          />
                          <Text
                            className="custom-message"
                            label="messageForYou"
                            style={{
                              color: "var(--envelopeColor)",
                              // color: "var(--primary)",
                              fontSize: "1.2rem",
                              textAlign: "center",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flex: 1,
                          }}
                        >
                          <div
                            style={{
                              color: "#fff",
                              fontSize: "0.9rem",
                              wordBreak: "break-word",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className="custom-message"
                              dangerouslySetInnerHTML={{
                                __html: customMessage.note,
                              }}
                            />
                            {/* {customMessage.note} */}
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="tracking-card-container">
                    <div className="card-section">
                      {isSchedulingAvailable() && (
                        <Card
                          className="card"
                          paddingTopContainer="0"
                          paddingCard="16px 24px 24px 24px"

                          /*  cardTitle={<Text label="prenotazione" />} */
                          // cardJustifyContent={
                          //   !isShippingDelivering() && "space-between"
                          // }
                        >
                          {props.loading ? (
                            <Spinner />
                          ) : // ) : !props.shipping?.estimatedDeliveryDate ? (
                          props.shipping?.events <= 1 ? (
                            // <div>
                            <div
                              style={{
                                alignItems: "center",
                                minHeight: 192,
                              }}
                              className="flex-row-center"
                            >
                              <Text
                                style={{ fontSize: 18 }}
                                label="ConsegnaNonInCarico"
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: !isShippingDelivering()
                                  ? "space-between"
                                  : "flex-start",
                                height: "100%",
                                flexDirection: "column",
                              }}
                            >
                              <h2 className="mb-05">
                                <Text
                                  style={{
                                    fontWeight: 500,
                                    fontSize: "0.8em",
                                    color: "var(--primary)",
                                  }}
                                  label="prenotazione"
                                />
                              </h2>
                              <div>
                                <div className="deliveryDate">
                                  {/* {props.shipping?.estimatedDeliveryDate ? (
                        moment(props.shipping.estimatedDeliveryDate).format( */}
                                  {props.shipping?.booking?.bookingDate ? (
                                    <div style={{ fontSize: 14 }}>
                                      <Text label="prenotazionePrevista" />
                                      <div>
                                        {moment(
                                          props.shipping?.booking?.bookingDate
                                        ).format("DD MMMM YYYY ")}
                                        <br />
                                        {formatTimeData(
                                          props.shipping?.booking?.slotTimeRange
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <Text
                                      style={{ fontSize: "1rem" }}
                                      label="dataConsegnaNonDisponibile"
                                    />
                                  )}
                                </div>
                              </div>
                              {!isShippingDelivering() &&
                                props.shipping?.serviceCode?.toLowerCase() !==
                                  "r" && (
                                  <div>
                                    <Text
                                      style={{
                                        fontWeight: 300,
                                        color: "var(--primary)",
                                        fontSize: 14,
                                      }}
                                      className="light-weight"
                                      label="prenotazionePersonalizzata"
                                    />
                                    <div className="flex-row-center">
                                      <Link
                                        className="link-style"
                                        to={
                                          key
                                            ? {
                                                pathname: "/booking",
                                              }
                                            : {
                                                pathname: "/richiesta-link",
                                                state: {
                                                  action: "BOOKING",
                                                  title: "richiestaPianifica",
                                                },
                                              }
                                        }
                                      >
                                        <ButtonLink
                                          color="#fff"
                                          backgroundColor="var(--primary)"
                                          marginTop="12px"
                                        >
                                          <Timer
                                            width={24}
                                            height={24}
                                            style={{ marginRight: 8 }}
                                          />
                                          <Text
                                            style={{ color: "#fff" }}
                                            label="pianifica"
                                          />
                                        </ButtonLink>
                                      </Link>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                        </Card>
                      )}
                      {!!props.pudoSetted ? (
                        <Card
                          minHeight={
                            isSchedulingAvailable() &&
                            !isShippingDelivering() &&
                            "240px"
                          }
                          paddingCard="16px 24px 24px 24px"
                          paddingBottomContainer="0"
                          /*          cardJustifyContent="center" */

                          /*  width={props.shipping?.pod && "32%"} */
                        >
                          <h2 className="mb-05">
                            <Text
                              style={{
                                fontWeight: 500,
                                fontSize: "0.8em",
                                color: "var(--primary)",
                              }}
                              label="indirizzo"
                            />
                          </h2>
                          <div
                            className="address-lines mb-1"
                            style={{ fontSize: 14 }}
                          >
                            <div
                              style={{ fontWeight: 500, lineHeight: "20px" }}
                            >
                              {" "}
                              {cleanUppercase(
                                props.pudoSetted.address.companyName
                              )}
                            </div>
                            <div style={{ lineHeight: "20px" }}>
                              {cleanUppercase(props.pudoSetted.address.street)}{" "}
                              {cleanUppercase(
                                props.pudoSetted.address.buildingNr
                              )}
                            </div>
                            <div style={{ lineHeight: "20px" }}>
                              {cleanUppercase(
                                props.pudoSetted.address.postalCode
                              )}
                              {", "}
                              {cleanUppercase(props.pudoSetted.address.city)}
                              {", "}
                              {cleanUppercase(props.pudoSetted.address.country)}
                            </div>
                          </div>
                          <Text
                            className="mt-1"
                            style={{
                              fontWeight: 500,
                              fontSize: "0.8rem",
                              color: "var(--primary)",
                              textAlign: "center",
                            }}
                            label="cambioIndirizzo"
                          />
                        </Card>
                      ) : (
                        <Card
                          // minHeight={
                          //   isSchedulingAvailable() &&
                          //   !isShippingDelivering() &&
                          //   "240px"
                          // }
                          paddingCard="16px 24px 24px 24px"
                          /*  width={props.shipping?.pod && "32%"} */
                          width={props.shipping?.pod && "100%"}
                          paddingBottomContainer="0"
                        >
                          <h2 className="mb-05">
                            <Text
                              style={{
                                fontWeight: 500,
                                fontSize: "0.8em",
                                color: "var(--primary)",
                              }}
                              label="indirizzo"
                            />
                          </h2>
                          {props.loading ? (
                            <Spinner />
                          ) : (
                            props.shipping?.deliveryAddress && (
                              <div
                                className="address-lines mb-1"
                                style={{ fontSize: 14 }}
                              >
                                <div style={{ lineHeight: "20px" }}>
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress
                                      .referencePerson
                                  )}
                                </div>
                                <div style={{ lineHeight: "20px" }}>
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress.street
                                  )}{" "}
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress.buildingNr
                                  )}
                                </div>
                                <div style={{ lineHeight: "20px" }}>
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress.postalCode
                                  )}
                                  {", "}
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress.city
                                  )}
                                  {", "}
                                  {cleanUppercase(
                                    props.shipping.deliveryAddress.country
                                  )}
                                </div>
                              </div>
                            )
                          )}
                          {pudos?.length > 0 && (
                            <>
                              <Text
                                label="pudoText"
                                style={{
                                  fontSize: "0.9rem",
                                  color: "var(--primary)",
                                  fontWeight: 300,
                                }}
                              />
                              <div className="flex-row-center">
                                <Link className="link-style" to="/select-pudo">
                                  <ButtonLink
                                    color="#fff"
                                    marginTop="12px"
                                    backgroundColor="var(--primary)"
                                  >
                                    <Text
                                      style={{ color: "#fff" }}
                                      label="selezionaPudo"
                                    />
                                  </ButtonLink>
                                </Link>
                              </div>
                            </>
                          )}
                        </Card>
                      )}
                      {isReturnAvailable() && (
                        <Card className="card">
                          <div
                            className="flex-row mb-05"
                            style={{ paddingBottom: 8, alignItems: "center" }}
                          >
                            <Package
                              width={28}
                              height={28}
                              style={{ marginRight: 8 }}
                            />
                            <h2>
                              <Text
                                style={{
                                  fontWeight: 500,
                                  fontSize: "0.8em",
                                  color: "var(--primary)",
                                }}
                                label="infoReso"
                              />
                            </h2>
                          </div>
                          <div className="flex-row-center">
                            <Link
                              className="link-style"
                              to={
                                key
                                  ? {
                                      pathname: "/reso",
                                    }
                                  : {
                                      pathname: "/richiesta-link",
                                      state: {
                                        action: "RETURN",
                                        title: "richiestaReso",
                                      },
                                    }
                              }
                            >
                              <ButtonLink
                                backgroundColor="var(--primary)"
                                border="1px solid var(--primary)"
                                marginTop={"8px"}
                              >
                                <Text
                                  style={{ color: "#fff" }}
                                  label="gestisciReso"
                                />
                              </ButtonLink>
                            </Link>
                          </div>
                        </Card>
                      )}
                    </div>
                    <TrackingSection
                      trackingEvents={groupEventsByState(
                        props.shipping.events,
                        props.shipping.provisionModeId
                      )}
                      storageId={props.shipping.warehouseStorageId}
                      provisionId={props.shipping.provisionModeId}
                      provisionLabel={props.shipping.provisionModeDescription}
                      getKoReason={getKoReason}
                      language={language}
                    />
                  </div>
                  {props.shipping?.pod && (
                    <Card
                      minHeight={isSchedulingAvailable() && "240px"}
                      half
                      cardTitle={<Text label="pod" />}
                      /* width="66%" */
                      width="100%"
                    >
                      {props.loading ? (
                        <Spinner />
                      ) : (
                        props.shipping?.pod && (
                          <div className="pod-container">
                            <div className="pod-map">
                              <PodMap
                                lat={props.shipping?.pod?.latitude}
                                lon={props.shipping?.pod?.longitude}
                              />
                              {props.shipping?.pod?.latitude === null && (
                                <div
                                  style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    backgroundColor: "white",
                                    opacity: 0.8,
                                    height: 240,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 500,
                                    }}
                                    label="posizioneNonDisponibile"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="pod-data">
                              <div>
                                <Text
                                  style={{ fontWeight: 600 }}
                                  label={"dateTime"}
                                />
                                {moment(props.shipping?.pod?.dateTime).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </div>{" "}
                              <div>
                                <Text
                                  style={{ marginTop: 12, fontWeight: 600 }}
                                  label={"consigneeName"}
                                />
                                {props.shipping?.pod?.consigneeName}
                              </div>{" "}
                              {props.shipping?.pod?.reservedAcceptance && (
                                <>
                                  <div>
                                    <Text
                                      style={{
                                        marginTop: 12,
                                        fontWeight: 600,
                                      }}
                                      label={"reservedAcceptance"}
                                    />
                                    {props.shipping?.pod?.reservedAcceptance ? (
                                      <Text label="Si" />
                                    ) : (
                                      "No"
                                    )}
                                  </div>
                                  <div>
                                    <Text
                                      style={{
                                        marginTop: 12,
                                        fontWeight: 600,
                                      }}
                                      label={"reservedAcceptanceMessage"}
                                    />
                                    {
                                      props.shipping?.pod
                                        ?.reservedAcceptanceMessage
                                    }
                                  </div>
                                </>
                              )}
                              {/* <div style={{ paddingLeft: 16 }}></div> */}
                              <Text
                                label={"signature"}
                                style={{
                                  fontWeight: 600,
                                  paddingTop: 12,
                                }}
                              />
                              <img
                                style={{ maxWidth: "100%" }}
                                src={`data:image/${props.shipping?.pod?.signature?.contentType};base64, ${props.shipping?.pod?.signature?.content}`}
                                alt={props.shipping?.pod?.signature?.fileName}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </Card>
                  )}
                  {props.shipping.provisionModeDescription && (
                    <Card
                      className="card"
                      cardTitle={<Text label="indicazioniSvincolo" />}
                      cardJustifyContent="space-between"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            color: "var(--primary)",
                            fontSize: "0.9rem",
                          }}
                        >
                          {props.shipping.provisionModeDescription}
                        </div>
                        <div
                          style={{
                            color: "var(--primary)",
                            fontSize: "0.9rem",
                          }}
                        >
                          {moment(
                            props.shipping.events.filter(
                              (event) => event.eventCode === 35
                            )[0].dateTime
                          ).format("DD/MM/YYYY - HH:mm")}
                        </div>
                      </div>
                    </Card>
                  )}
                  {props.shipping.events[props.shipping.events.length - 1]
                    .eventCode === 35 &&
                    !props.shipping.provisionModeDescription && (
                      <Card
                        className="card"
                        cardTitle={<Text label="indicazioniSvincolo" />}
                        cardJustifyContent="space-between"
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            style={{
                              color: "var(--primary)",
                              fontSize: "0.9rem",
                            }}
                            label="attesaIstruzioni"
                          />
                          <div
                            style={{
                              color: "var(--primary)",
                              fontSize: "0.9rem",
                            }}
                          >
                            {moment(
                              props.shipping.events.filter(
                                (event) => event.eventCode === 35
                              )[0].dateTime
                            ).format("DD/MM/YYYY - HH:mm")}
                          </div>
                        </div>
                      </Card>
                    )}
                </div>
              )}
            </>
          )}

          <div className="mobile-buttons-container">
            {isAssistanceAvailable() && (
              <HelpSection
                textColor="var(--primary)"
                title="assistenzaClienti"
                body="assistenza"
                handleClick={() => setIsPopupOpen("contact")}
                buttonLabel="contattaci"
                backgroundColor="#fff"
              />
            )}
            {isWhatsappAvailable && (
              <HelpSection
                backgroundColorBtn="var(--bgSecondaryBtnColor)"
                textColorBtn="var(--textSecondaryBtnColor)"
                textColor="var(--textInSecondaryColor)"
                title="whatsapp"
                body={
                  !verificationComplete
                    ? "inserisciNumero"
                    : "inserisciNumeroInterrompi"
                }
                handleClick={() => setIsPopupOpen("whatsapp")}
                buttonLabel={
                  !verificationComplete
                    ? "attivaServizio"
                    : "interrompiAggiornamento"
                }
              />
            )}

            {/*         {isReturnAvailable() && (
              <div
                style={{
                  padding: "32px 10%",
                  borderTopWidth: 1,
                  borderTopColor: "#e2e2e2",
                  borderTopStyle: "solid",
                  backgroundColor: "#fff",
                }}
              >
                <div className="flex-row" style={{ paddingBottom: 8 }}>
                  <Package width={28} height={28} style={{ marginRight: 8 }} />
                  <div>
                    <Text
                      style={{ fontSize: 14, color: "var(--primary)" }}
                      label="infoReso"
                    />
                  </div>
                </div>
                <div className="flex-row-center">
                  <Link
                    className="link-style"
                    to={
                      key
                        ? {
                            pathname: "/reso",
                          }
                        : {
                            pathname: "/richiesta-link",
                            state: {
                              action: "RETURN",
                              title: "richiestaReso",
                            },
                          }
                    }
                  >
                    <ButtonLink
                      backgroundColor="var(--primary)"
                      border="1px solid var(--primary)"
                      marginTop={"8px"}
                      style={{
                        fontSize: 10,
                        textTransform: "uppercase",
                        fontWeight: 700,
                      }}
                    >
                      <Text style={{ color: "#fff" }} label="gestisciReso" />
                    </ButtonLink>
                  </Link>
                </div>
              </div>
            )} */}
          </div>
          <MobileFooter />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.language.language,
  pudoSetted: state.shippings.pudoSetted,
  shipping: state.shippings.data,
  loading: state.shippings.loading,
  error: state.shippings.error,
});

export default connect(mapStateToProps, {
  setReduxLanguage,
  sendMessage,
})(TrackingPage);

const cleanUppercase = (string) => {
  if (!string) return "";
  return string.split(" ").map(capitalizeFirst).join(" ");
};

const capitalizeFirst = (string) => {
  if (!string) return "";
  return string[0].toUpperCase() + string.slice(1, string.length).toLowerCase();
};
